<template>
  <div class="viewOnlineExam">
    <div v-if="!error">
      <v-row>
        <v-col cols="12">
          <div class="title">
            <h2 class="main-color">
              {{ ExamData.exam_name }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="info-list">
          <ul class="inline">
            <li>
              <strong>{{ $t("Applicant Name") }}:</strong>
              {{ ExamData.student_name }}
            </li>
            <li>
              <strong>{{ $t("Duration") }}:</strong>
              {{ ExamData.duration_hour }}:{{ ExamData.duration_min }}
            </li>
            <li>
              <strong>{{ $t("Time to answer") }}:</strong>
              {{ ExamData.time_spent }}
            </li>

            <li>
              <strong>{{ $t("Exam Marks") }}:</strong>
              {{ ExamData.exam_mark }}
            </li>
            <li>
              <strong>{{ $t("Applicant Mark") }}:</strong>
              {{ ExamData.applicant_answer_mark }}
            </li>
          </ul>
        </v-col>

        <v-col cols="12" md="12" class="hr-div">
          <hr />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="12"
          class="sectionBody"
          v-for="(section, index) in ExamData['sections']"
          :key="index"
        >
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="12">
                <p class="section-title main-color">
                  {{ section.section_title }}
                </p>
              </v-col>
            </v-row>
            <v-row
              v-for="(question, qindex) in section.questions"
              :key="qindex"
            >
              <v-col cols="12" sm="12">
                <v-row>
                  <v-col
                    cols="1"
                    sm="1"
                    v-if="question.type != 'join' && question.type != 'essay'"
                  >
                    <v-icon
                      color="red"
                      dark
                      v-if="question.correct_answer != question.student_answer"
                      >close</v-icon
                    >
                    <v-icon
                      color="green"
                      dark
                      v-if="question.correct_answer == question.student_answer"
                      >check</v-icon
                    >
                  </v-col>
                  <v-col cols="6" sm="6">
                    <p
                      class="text-left question"
                      :style="
                        question.type == 'mcq' || question.type == 'tof'
                          ? 'margin-left:-70px;'
                          : ''
                      "
                    >
                      <strong v-html="question.question"></strong>
                    </p>
                  </v-col>
                  <v-col cols="3" sm="3">
                    <v-row>
                      <v-col cols="6">
                        <p class="main-color">{{ $t("Applicant Mark") }}</p>
                      </v-col>
                      <v-col cols="6" style="">
                        <strong>{{ question.student_mark }}</strong>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2" sm="2">
                    <p class="text-right main-color">
                      {{ $t("Question Marks") }}: {{ question.Marks }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                style="padding-top: 0; margin-left: 30px"
                :style="question.type != 'join' ? 'margin-top:-30px;' : ''"
              >
                <div v-if="question.type == 'essay'">
                  <p v-html="question.student_answer"></p>
                  <br />{{ $t("Correct Answer") }}:
                  <p style="color: green" v-html="question.correct_answer"></p>
                </div>
                <div v-if="question.type == 'tof' || question.type == 'mcq'">
                  <v-row>
                    <v-col cols="10">
                      <v-radio-group
                        v-model="question.student_answer"
                        row
                        readonly
                      >
                        <v-radio
                          v-for="(option, Oindex) in question.options"
                          :key="Oindex"
                          label=""
                          :value="option.value"
                          :color="
                            question.student_answer == question.correct_answer
                              ? 'green'
                              : 'red'
                          "
                          readonly
                        >
                          <template slot="label">
                            <p
                              class="answer-img-container"
                              v-html="option.value"
                            ></p>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="2">
                      <p style="color: green">
                        Correct Answer :
                        <span v-html="question.correct_answer"></span>
                      </p>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="question.type == 'join'">
                  <v-row v-for="(join, Jindex) in question.joins" :key="Jindex">
                    <v-col cols="1" sm="1">
                      <v-icon
                        :hidden="
                          join.join_question == '' || join.join_question == null
                        "
                        color="red"
                        dark
                        v-if="join.correct_answer != join.student_answer"
                        >close</v-icon
                      >
                      <v-icon
                        :hidden="
                          join.join_question == '' || join.join_question == null
                        "
                        color="green"
                        dark
                        v-if="join.correct_answer == join.student_answer"
                        >check</v-icon
                      >
                    </v-col>
                    <v-col cols="2" style="margin-left: -70px">
                      <p
                        :hidden="
                          join.join_question == '' || join.join_question == null
                        "
                      >
                        {{ join.join_question_number }} .
                        <span v-html="join.join_question"></span>
                      </p>
                    </v-col>
                    <v-col cols="1">
                      <div
                        :hidden="
                          join.join_question == '' || join.join_question == null
                        "
                      >
                        <v-select
                          :items="join.all_answers_items"
                          solo
                          v-model="join.student_answer"
                          readonly
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <p>
                        {{ join.join_answer_number }} .
                        <span v-html="join.join_answer"></span>
                      </p>
                    </v-col>
                    <v-col cols="1">
                      <p style="color: green">{{ join.correct_answer }}</p>
                    </v-col>
                    <v-col cols="3" sm="3">
                      <v-row>
                        <v-col cols="6">
                          <p class="main-color">{{ $t("Applicant Mark") }}</p>
                        </v-col>
                        <v-col cols="6" style="margin-top: -20px">
                          <p>{{ join.student_join_mark }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <hr />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
        :right="true"
        :top="true"
      >
        <!-- {{ snackText }} -->

        <span class="white--text">{{ snackText }}</span>

        <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
      </v-snackbar>
    </div>
    <div v-if="error">
      <v-row>
        <v-col cols="12" md="12">
          <v-alert class="info_alert">{{
            $t("This Student Not Solved This Exam Yet.")
          }}</v-alert>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../../mixins/validationMixin";
export default {
  name: "viewApplicantAnswer",
  mixins: [validationMixin],
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      valid: false,
      ExamData: {},
      sectionsData: [],
      examSaved: "",
      confirmed: "",
      loaded: false,
      examId: "",
      applicant_code: "",
      error: false,
      numeric: {
        numeric: v => v >= 0 || "Number should be more or equal to 0"
      }
    };
  },
  methods: {
    CallculateQuestionMark(index, qindex) {
      let student_mark = 0;
      // console.log(qindex);
      this.ExamData["sections"][index]["questions"][qindex]["joins"].forEach(
        join => {
          if (join.student_join_mark != null)
            student_mark += parseFloat(join.student_join_mark);
        }
      );
      // console.log(student_mark);
      this.ExamData["sections"][index]["questions"][qindex][
        "student_mark"
      ] = student_mark;
    },
    CheckStudentMark(index, qindex) {
      if (
        parseInt(
          this.ExamData["sections"][index]["questions"][qindex]["student_mark"]
        ) >
        parseInt(this.ExamData["sections"][index]["questions"][qindex]["Marks"])
      ) {
        return "Shouldnot exceed question mark";
      }
      return true;
    },
    getExamData() {
      axios
        .get(
          this.getApiUrl +
            "/onlineexams/applicantExamEvaluation/" +
            this.examId +
            "/" +
            this.applicant_code,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error) {
            this.error = true;
            this.snackColor = "red";
            this.snack = true;
            this.snackText = response.data.status.message;
          } else {
            this.ExamData = response.data.data;

            this.examSaved = this.ExamData.saved;
            this.confirmed = this.ExamData.confirmed;
            let _this = this;
            if (!this.confirmed && !this.examSaved) {
              this.ExamData["sections"].forEach(function(section, index) {
                section.questions.forEach(function(question, qindex) {
                  if (question.type == "tof" || question.type == "mcq") {
                    if (question.student_answer == question.correct_answer) {
                      question.student_mark = question.Marks;
                    } else {
                      question.student_mark = 0;
                    }
                  } else if (question.type == "join") {
                    // this.calculateJoinMarks(question).then(
                    //   this.CallculateQuestionMark(index, qindex)
                    // );
                    question.joins.forEach(join => {
                      if (join.correct_answer == join.student_answer) {
                        join.student_join_mark = join.join_mark;
                      } else {
                        join.student_join_mark = 0;
                      }
                    });
                    // console.log(index + "section");
                    _this.CallculateQuestionMark(index, qindex);
                  }
                });
              });
            }
            this.loaded = true;
          }
        });
    }
    // async calculateJoinMarks(question) {
    //   question.joins.forEach(join => {
    //     if (join.correct_answer == join.student_answer) {
    //       join.student_join_mark = join.join_mark;
    //     } else {
    //       join.student_join_mark = 0;
    //     }
    //   });
    // },
  },
  mounted() {
    this.applicant_code = this.$router.currentRoute.params.applicant_code;
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.sectionBody {
  box-shadow: $box-shadow;
  margin-top: 1rem;
}
.question {
  margin-bottom: 0;
}
.question::after {
  clear: both;
}
.section-title {
  font-size: 1.4rem;
}
</style>
